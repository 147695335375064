import React, { useEffect, useState } from "react";
import { CgFileAdd } from "react-icons/cg";
import axios from "axios";
import { DealerApi } from "../Component/Api";
import "./PlaceAdd.css";
import Cookies from "js-cookie";
import { IoCreate } from "react-icons/io5";
import { useNavigate } from "react-router";
import { IoIosArrowForward } from "react-icons/io";
import Heder from "./Heder";

const PlaceAdd = ({setting}) => {
  const navigate = useNavigate();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const listing = Cookies.get("listingToken");
  const listingObject = listing && JSON.parse(listing);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [county, setCounty] = useState({ county: "", town: "" });
  const [dropcounty, setDropCounty] = useState([]);
  const [droptown, setDropTown] = useState([]);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [bodytype, setBodytype] = useState([]);
  const [colour, setColour] = useState([]);
  const [engine, setEngine] = useState([]);
  const [telephonePublish, setTelephonePublish] = useState(false);
  const [mobilePublish, setMobilePublish] = useState(false);
  const [emailPublish, setEmailPublish] = useState(false);

  const initialValues = {
    user_id: tokenObject && tokenObject.id,
    listing_type: "ad",
    ad_title: "",
    description: "",
    category_id: null,
    sub_category_id: null,
    price: "",
    currency: "GBP",
    county_id: tokenObject && tokenObject.county_id,
    town_id: tokenObject && tokenObject.town_id,
    telephone: tokenObject && tokenObject.telephone,
    mobile: tokenObject && tokenObject.mobile,
    email: tokenObject && tokenObject.email,
    buy_now_url: "",
    make_id: 0,
    model_id: 0,
    fuel: "",
    body_type: "",
    transmission: "",
    year: "",
    colour: "",
    engine_size: "",
    tax_date: "",
    nct_date: "",
    mileage: "",
    version: "",
    co2_emissions: "",
    comfort: [],
    visibility_aids: [],
    security: [],
    edition_one: "no",
    edition_two: "no",
    edition_three: "no",
    telephone_publish: telephonePublish,
    mobile_publish: mobilePublish,
    email_publish: emailPublish,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [id, setId] = useState(null);
  const [isPriceDisabled, setIsPriceDisabled] = useState(false);

  const [edition1Visible, setEdition1Visible] = useState(false);
  const [edition2Visible, setEdition2Visible] = useState(false);
  const [edition3Visible, setEdition3Visible] = useState(false);

  const printNI = [1, 2, 3, 4, 5, 6];
  const printDL = [4, 5, 6, 8, 11, 13, 18, 19, 22, 24, 26, 27];
  const printMID = [8, 24];

  useEffect(() => {
    const countyId = formValues.county_id;
    if (countyId) {
      const edition1Visible = printDL.includes(+countyId);
      const edition2Visible = printNI.includes(+countyId);
      const edition3Visible = printMID.includes(+countyId);

      setEdition1Visible(edition1Visible);
      setEdition2Visible(edition2Visible);
      setEdition3Visible(edition3Visible);

      if (tokenObject.user_type == "private") {
        setFormValues((prevValues) => ({
          ...prevValues,
          edition_one: edition1Visible ? "yes" : prevValues.edition_one,
          edition_two: edition2Visible ? "yes" : prevValues.edition_two,
          edition_three: edition3Visible ? "yes" : prevValues.edition_three,
        }));
      }
    }
  }, []);

  const handleCurrencyChange = (e) => {
    const { value } = e.target;
    handleChange(e);

    if (value == "FREE") {
      setFormValues((prevValues) => ({
        ...prevValues,
        price: 0,
      }));
      setIsPriceDisabled(true);
    } else {
      setIsPriceDisabled(false);
    }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${DealerApi}/category`);
        setCategory(response.data.body.result);
      } catch (error) {}
    };

    fetchCategory();
  }, []);

  useEffect(() => {
    if (tokenObject) {
      let id = tokenObject.id;
      let token1 = tokenObject.token;
      axios
        .get(`${DealerApi}/user/${id}`, {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        })
        .then((res) => {
          setCounty(res.data.body.result);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    const fetchCounty = async () => {
      try {
        const response = await axios.get(`${DealerApi}/county`);
        setDropCounty(response.data.body.result);
      } catch (error) {}
    };

    fetchCounty();
  }, []);

  useEffect(() => {
    const fetchTown = async () => {
      let tokencounty = tokenObject.county_id;
      try {
        const response = await axios.get(`${DealerApi}/town/${tokencounty}`);
        setDropTown(response.data.body.result);
      } catch (error) {}
    };

    fetchTown();
  }, []);

  useEffect(() => {
    const fetchMakes = async () => {
      try {
        // const response = await axios.get(`${DealerApi}/filter/makes`);
        const response = await axios.get(`${DealerApi}/car/make`);

       
        setMake(response.data.body.result);
      } catch (error) {}
    };

    fetchMakes();
  }, []);

  useEffect(() => {
    const fetchFuel = async () => {
      try {
        const response = await axios.get(`${DealerApi}/filter/fuel`);
        setFuel(response.data.body.result);
      } catch (error) {}
    };

    fetchFuel();
  }, []);

  useEffect(() => {
    const fetchBodyType = async () => {
      try {
        const response = await axios.get(`${DealerApi}/filter/bodytypes`);
        setBodytype(response.data.body.result);
      } catch (error) {}
    };

    fetchBodyType();
  }, []);

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const generateYearOptions = () => {
    const currentYear = getCurrentYear();
    const years = [];

    for (let year = currentYear; year >= currentYear - 100; year--) {
      years.push(year);
    }

    return years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  useEffect(() => {
    const fetchColour = async () => {
      try {
        const response = await axios.get(`${DealerApi}/filter/colours`);
        setColour(response.data.body.result);
      } catch (error) {}
    };

    fetchColour();
  }, []);

  useEffect(() => {
    const fetchEngine = async () => {
      try {
        const response = await axios.get(`${DealerApi}/filter/enginesizes`);
        setEngine(response.data.body.result);
      } catch (error) {}
    };

    fetchEngine();
  }, []);

  const handleComfortCheck = (e) => {
    const { name, value, checked } = e.target;
    

    if (name === "comfort") {
      if (checked) {
        const com = [...(formValues.comfort || []), value];
        setFormValues({
          ...formValues,
          comfort: com,
        });
      } else {
        setFormValues({
          ...formValues,
          comfort:
            formValues.comfort &&
            formValues.comfort.filter((item) => item !== value),
        });
      }
    }
  };

  const handleVisibiltyCheck = (e) => {
    const { name, value, checked } = e.target;
    if (name === "visibility_aids") {
      if (checked) {
        const com = [...(formValues.visibility_aids || []), value];
        setFormValues({
          ...formValues,
          visibility_aids: com,
        });
      } else {
        setFormValues({
          ...formValues,
          visibility_aids: formValues.visibility_aids.filter(
            (item) => item !== value
          ),
        });
      }
    }
  };

  const handleSecurityCheck = (e) => {
    const { name, checked } = e.target;
    if (name === "security") {
      const value = e.target.value;
      if (checked) {
        const com = [...(formValues.security || []), value];
        setFormValues({
          ...formValues,
          security: com,
        });
      } else {
        setFormValues({
          ...formValues,
          security:
            formValues && formValues.security.filter((item) => item !== value),
        });
      }
    }
  };

  const handleChange = async (e) => {
    const { name, value, checked, type } = e.target;
   
    if (type === "checkbox") {
      setFormValues({ ...formValues, [name]: checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
    if (name === "category_id") {
      try {
        const selectedCategory = category.find((cat) => cat.id == value);
        if (selectedCategory) {
          const response = await axios.get(
            `${DealerApi}/category/${selectedCategory.slug}`
          );
          setSubCategory(response.data.body.result);
        }
      } catch (error) {}
    }

    if (name === "county_id") {
      const selectedCounty = dropcounty.find((item) => item.id == value);
      if (selectedCounty) {
        setCounty({ county: selectedCounty.county, town: "" });
        setDropTown([]);
        try {
          const response = await axios.get(`${DealerApi}/town/${value}`);
          setDropTown(response.data.body.result);
          setFormValues({ ...formValues, [name]: value, town_id: "" });
        } catch (error) {}
      }
      const edition1Visible = printDL.includes(+value);
      const edition2Visible = printNI.includes(+value);
      const edition3Visible = printMID.includes(+value);

      setEdition1Visible(edition1Visible);
      setEdition2Visible(edition2Visible);
      setEdition3Visible(edition3Visible);

      if (tokenObject.user_type == "private") {
        setFormValues((prevValues) => ({
          ...prevValues,
          edition_one: edition1Visible ? "yes" : "no",
          edition_two: edition2Visible ? "yes" : "no",
          edition_three: edition3Visible ? "yes" : "no",
        }));
      }
    }

    if (name === "make_id") {
      if (value == 0) {
        setModel([]);
      } else {
        try {
     
          const selectedMake = make.find((make) => make.id == value);
        
          if (selectedMake) {
            // const response = await axios.get(
            //   `${DealerApi}/filter/models?make_slug=${selectedMake.slug}`
            // );
            const response = await axios.get(
              `${DealerApi}/car/model/${selectedMake.id}`
            );
            setModel(response.data.body.result);
          }
        } catch (error) {}
      }
    }
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};
    // const regexNumber = /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
    // const regexNumber = /^[0-9]{1,20}$/;
    const regexNumber = /^[\+\-()0-9\s]{1,20}$/;

    if (!formValues.listing_type) {
      errors.listing_type = "List type is required";
    }

    if (!formValues.ad_title) {
      errors.ad_title = "Ad Title is required";
    }

    if (!formValues.description) {
      errors.description = "Description is required";
    }

    if (!formValues.category_id) {
      errors.category_id = "Please select Category";
    }

    if (!formValues.sub_category_id) {
      errors.sub_category_id = "Please select Sub Category";
    }

    if (formValues.listing_type == "ad" && formValues.currency != "FREE") {
      if (!formValues.price) {
        errors.price = "Price is required";
      } else if (formValues.price <= 0) {
        errors.price = "Price must be greater than 0";
      }
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.make_id
    ) {
      errors.make_id = "Please select Car Make";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.model_id
    ) {
      errors.model_id = "Please select Car Model";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.fuel
    ) {
      errors.fuel = "Please select Fuel";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.body_type
    ) {
      errors.body_type = "Please select Body Type";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.year
    ) {
      errors.year = "Please select Year Of Vehicle";
    }

    if (
      (formValues.sub_category_id === "47" ||
        formValues.sub_category_id === "52") &&
      !formValues.colour
    ) {
      errors.colour = "Please select Colour";
    }

    // if (
    //   (formValues.sub_category_id === "47" ||
    //     formValues.sub_category_id === "52") &&
    //   !formValues.engine_size
    // ) {
    //   errors.engine_size = "Please select Engine Size";
    // }

    if (!formValues.county_id) {
      errors.county_id = "Please select County";
    }

    if (!formValues.town_id) {
      errors.town_id = "Please select Town";
    }

    if (!formValues.mobile) {
      errors.mobile = "Mobile is required";
    } else if (!regexNumber.test(formValues.mobile)) {
      errors.mobile = "Mobile should have maximum length 20";
    }

    if (
      !formValues.email_publish &&
      !formValues.mobile_publish &&
      !formValues.telephone_publish
    ) {
      errors.publish = "At least one publish option is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    let values = {
      ...formValues,
      comfort: formValues.comfort && formValues.comfort.join(","),
      visibility_aids:
        formValues.visibility_aids && formValues.visibility_aids.join(","),
      security: formValues.security && formValues.security.join(","),
      email_publish: formValues.email_publish ? 1 : 0,
      telephone_publish: formValues.telephone_publish ? 1 : 0,
      mobile_publish: formValues.mobile_publish ? 1 : 0,
    };
   
    if (Object.keys(errors).length === 0) {
      if (!listingObject) {
        axios
          .post(`${DealerApi}/listing`, values, {
            headers: {
              Authorization: `Bearer ${tokenObject.token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.data.body.message === "Listing was Added!") {
              setId(res.data.body.result.listing_id);
              Cookies.set("listingToken", JSON.stringify(res.data.body.result));
              setTimeout(() => {
                navigate("/online_ad/uploads");
              }, 1000);
            }
          })
          .catch((error) => {});
      } else {
        const edit_value = {
          listing_type: formValues.listing_type,
          ad_title: formValues.ad_title,
          description: formValues.description,
          category_id: formValues.category_id,
          sub_category_id: formValues.sub_category_id,
          currency: formValues.currency,
          price: formValues.price,
          county_id: formValues.county_id,
          town_id: formValues.town_id,
          telephone: formValues.telephone,
          mobile: formValues.mobile,
          email: formValues.email,
          make_id: formValues.make_id,
          model_id: formValues.model_id,
          fuel: formValues.fuel,
          body_type: formValues.body_type,
          transmission: formValues.transmission,
          year: formValues.year,
          colour: formValues.colour,
          engine_size: formValues.engine_size,
          tax_date: formValues.tax_date,
          nct_date: formValues.nct_date,
          mileage: formValues.mileage,
          version: formValues.version,
          co2_emissions: formValues.co2_emissions,
          comfort: formValues.comfort && formValues.comfort.join(","),
          visibility_aids:
            formValues.visibility_aids && formValues.visibility_aids.join(","),
          security: formValues.security && formValues.security.join(","),
          edition_one: formValues.edition_one,
          edition_two: formValues.edition_two,
          edition_three: formValues.edition_three,
          telephone_publish: formValues.telephone_publish,
          mobile_publish: formValues.mobile_publish,
          email_publish: formValues.email_publish,
        };
        axios
          .patch(
            `${DealerApi}/listing/${listingObject && listingObject.listing_id}`,
            edit_value,
            {
              headers: {
                Authorization: `Bearer ${tokenObject.token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setTimeout(() => {
              navigate(`/online_ad/uploads/${listingObject && listingObject.listing_id}`);
            }, 1000);
          })
          .catch((error) => {});
      }
    } else {
      setFormErrors(errors);
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorElement = document.querySelector(
        `[name="${firstErrorKey}"]`
      );
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: "smooth" });
        firstErrorElement.focus();
      }
    }
  };

  useEffect(() => {
    if (listingObject && listingObject.listing_id) {
      axios
        .get(`${DealerApi}/listing/${listingObject.listing_id}`, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((res) => {
          let comfort = [
            "Air Conditioning",
            "Phone Kit",
            "Front Electric Windows",
            "Rear Electric Windows",
            "Heated Seats",
            "Leather Seats",
            "Cloth interior",
            "Split Rear Seats",
            "Bluetooth",
            "DVD System",
            "Metallic Paint",
            "Colour Coded",
            "Lumbar Support",
            "Roof Rail",
            "Electric Front Seats",
            "Trip Computer",
            "6 Disc CD",
          ];

          let visibility = [
            "Heated Mirrors",
            "Xenon Headlights",
            "Headlamp Wash",
            "Manual Rain Sensors",
            "Parking Sensors",
            "Reversing Camera",
            "Front Fogs",
          ];

          let safety = [
            "Anti Lock Brakes",
            "ESP",
            "Power Steering",
            "Rake/Reach Steering",
            "Remote Locking",
            "Immobiliser",
            "Alarm",
            "Side Airbags",
            "Driver Airbag",
            "Passenger Airbag",
            "3x3 Rear Seat Belts",
          ];

          let comfortData = [];
          let visibilityData = [];
          let safetyData = [];
          if (
            res &&
            res.data &&
            res.data.body &&
            res.data.body.result &&
            res.data.body.result.search_options
          ) {
            res.data.body.result.search_options.forEach((item) => {
              if (comfort.includes(item)) {
                comfortData.push(item);
              } else if (visibility.includes(item)) {
                visibilityData.push(item);
              } else if (safety.includes(item)) {
                safetyData.push(item);
              }
            });
          }
          const { search_options, ...restdata } = res.data.body.result;

          const data = {
            ...restdata,
            comfort: comfortData,
            visibility_aids: visibilityData,
            security: safetyData,
            price:
              res &&
              res.data &&
              res.data.body &&
              res.data.body.result &&
              res.data.body.result.price == ""
                ? 0
                : res.data.body.result.price,
            currency:
              res &&
              res.data &&
              res.data.body &&
              res.data.body.result &&
              res.data.body.result.currency,
          };
          if (res.data.body.result && res.data.body.result.currency == "FREE") {
            setIsPriceDisabled(true);
          }
          setFormValues(data);

          const fetchCategory = async () => {
            try {
              const response = await axios.get(`${DealerApi}/category`);

              const selectedCategory = response.data.body.result.find(
                (cat) => cat.id == res.data.body.result.category_id
              );
              if (selectedCategory) {
                axios
                  .get(`${DealerApi}/category/${selectedCategory.slug}`)
                  .then((res) => {
                    setSubCategory(res.data.body.result);
                  })
                  .catch((err) => {});
              }
            } catch (error) {}
          };
          fetchCategory();

          const fetchMakes = async () => {
            try {
              const response = await axios.get(`${DealerApi}/car/make`);
              const selectedCategory = response.data.body.result.find(
                (cat) => cat.id == res.data.body.result.make_id
              );
            
              if (selectedCategory) {
                // const response = await axios.get(
                //   `${DealerApi}/filter/models?make_slug=${selectedCategory.slug}`
                // );
                const response = await axios.get(
                  `${DealerApi}/car/model/${selectedCategory.id}`
                );
                setModel(response.data.body.result);
              }
            } catch (error) {}
          };
          fetchMakes();

          const fetchTown = async () => {
            let tokencounty = res.data.body.result.county_id;
            try {
              const response = await axios.get(
                `${DealerApi}/town/${tokencounty}`
              );
              setDropTown(response.data.body.result);
            } catch (error) {}
          };
          fetchTown();
        })
        .catch((err) => {});
    }
  }, []);

  const shouldShowHeading =
    edition1Visible || edition2Visible || edition3Visible;

  return (
    <>
      <Heder name="online ad form" setting={setting}/>
      <div className="advert_main_div">
        <p className="advert_heading">Advert Details</p>
      </div>
      <div className="container">
        <div className="advert_main">
          <div className="advert_info">
            <div className="advert_form">
              <h1 className="place_ad_heading">
                <div className="register_heading_div">
                  <CgFileAdd />
                  Ad Information
                </div>
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="place_ad_div">
                  <div className="place_ad_left_portion">
                    <div className="register_radio">
                      <input
                        type="radio"
                        name="listing_type"
                        value="ad"
                        onChange={handleChange}
                        checked={formValues.listing_type === "ad"}
                        id="user_type1"
                      />
                      <label className="register_label" htmlFor="user_type1">
                        Ad
                      </label>
                      <input
                        type="radio"
                        name="listing_type"
                        value="want"
                        onChange={handleChange}
                        checked={formValues.listing_type === "want"}
                        id="user_type2"
                      />
                      <label className="register_label" htmlFor="user_type2">
                        Want
                      </label>
                    </div>

                    <div className="error-message">
                      {formErrors.listing_type}
                    </div>

                    <label className="register_label company_name">
                      Ad Title <span className="astrick">*</span>
                    </label>
                    <input
                      type="text"
                      className="register_input"
                      name="ad_title"
                      value={formValues.ad_title}
                      onChange={handleChange}
                    />

                    <div className="error-message">{formErrors.ad_title}</div>

                    <label className="register_label company_name">
                      Detailed Description <span className="astrick">*</span>
                    </label>
                    <textarea
                      className="register_input_description"
                      rows="8"
                      name="description"
                      value={formValues.description}
                      onChange={handleChange}
                    />

                    <div className="error-message">
                      {formErrors.description}
                    </div>

                    {tokenObject.allow_buy_now == "yes" &&
                      formValues.listing_type == "ad" && (
                        <>
                          <label className="register_label company_name">
                            Buy Now Button
                          </label>
                          <label className="currency_label">
                            Enter URL of this item on your website
                          </label>
                          <input
                            type="url"
                            className="register_input"
                            name="buy_now_url"
                            value={formValues.buy_now_url}
                            onChange={handleChange}
                          />
                        </>
                      )}

                    {formValues.listing_type == "ad" && (
                      <>
                        <label className="register_label company_name">
                          Price <span className="astrick">*</span>
                        </label>
                        <label className="currency_label">
                          {tokenObject.user_type == "business"
                            ? "Select currency or FREE from dropdown"
                            : "Select currency from dropdown"}
                        </label>
                        <div className="price_div">
                          <select
                            name="currency"
                            className="registerinput"
                            value={formValues.currency}
                            onChange={handleCurrencyChange}
                          >
                            <option value="GBP">£</option>
                            <option value="EUR">€</option>
                            {tokenObject.user_type == "business" && (
                              <option value="FREE">P.O.A</option>
                            )}
                          </select>

                          <input
                            type="number"
                            className="register_input"
                            name="price"
                            value={formValues.price}
                            onChange={handleChange}
                            disabled={isPriceDisabled}
                          />
                        </div>
                      </>
                    )}
                    <div className="error-message">{formErrors.price}</div>

                    <label className="register_label company_name">
                      Email <span className="astrick">*</span>
                    </label>
                    <input
                      type="email"
                      className="register_input"
                      name="email"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                    <div className="error-message">{formErrors.email}</div>

                    <div>
                      <div className="place_ad_form_div">
                        <div className="ad_left_portion">
                          <label className="reg_heading company_name">
                            Location
                          </label>
                          <>
                            <label className="register_label">
                              County <span className="astrick">*</span>
                            </label>
                            <div className="price_div">
                              <select
                                name="county_id"
                                className="register_input"
                                onChange={handleChange}
                                value={formValues.county_id}
                              >
                                {dropcounty.map((county) => (
                                  <option
                                    key={county.id}
                                    value={county.id}
                                    selected={
                                      county.id == tokenObject &&
                                      tokenObject.county_id &&
                                      "selected"
                                    }
                                  >
                                    {county.county}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="error-message">
                              {formErrors.county_id}
                            </div>

                            <label className="register_label company_name">
                              Main Category <span className="astrick">*</span>
                            </label>
                            <select
                              name="category_id"
                              id="category_id"
                              className="register_input"
                              value={formValues.category_id}
                              onChange={handleChange}
                            >
                              <option value="">
                                Select Your Main Category
                              </option>
                              {category.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.category}
                                </option>
                              ))}
                            </select>

                            <div className="error-message">
                              {formErrors.category_id}
                            </div>
                          </>
                        </div>
                        <div className="ad_right_portion">
                          <label className="register_label town_name">
                            Town <span className="astrick">*</span>
                          </label>
                          <div className="price_div">
                            <select
                              name="town_id"
                              className="register_input"
                              onChange={handleChange}
                              value={formValues.town_id}
                            >
                              <option value="">Select Town</option>
                              {droptown.map((town) => (
                                <option
                                  key={town.id}
                                  value={town.id}
                                  selected={
                                    town.id == tokenObject &&
                                    tokenObject.town_id &&
                                    "selected"
                                  }
                                >
                                  {town.town}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="error-message">
                            {formErrors.town_id}
                          </div>

                          <label className="register_label company_name">
                            Sub Category <span className="astrick">*</span>
                          </label>
                          <select
                            name="sub_category_id"
                            id="sub_category_id"
                            className="register_input"
                            value={formValues.sub_category_id}
                            onChange={handleChange}
                          >
                            <option value="">All Sub-Categories</option>
                            {subCategory.map((subcategory) => (
                              <option
                                key={subcategory.id}
                                value={subcategory.id}
                              >
                                {subcategory.category}
                              </option>
                            ))}
                          </select>

                          <div className="error-message">
                            {formErrors.sub_category_id}
                          </div>
                        </div>
                      </div>

                      <div>
                        {(formValues.sub_category_id == "47" ||
                          formValues.sub_category_id == "52") && (
                          <>
                            <label
                              className="register_label company_name"
                              style={{ fontWeight: "bold" }}
                            >
                              Car Details
                            </label>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label">
                                  Car Make<span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="make_id"
                                    id="make_id"
                                    className="car_input"
                                    value={formValues.make_id}
                                    onChange={handleChange}
                                  >
                                    <option value="0">Select Make</option>
                                    {make.map((make) => (
                                      <option
                                        key={make.make_id}
                                        value={make.id}
                                      >
                                        {make.make}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.make_id}
                                  </div>
                                </div>
                              </div>

                              <div className="car_div">
                                <label className="register_label">
                                  Car Model<span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="model_id"
                                    id="model_id"
                                    className="car_input"
                                    value={formValues.model_id}
                                    onChange={handleChange}
                                  >
                                    <option value="0">Any Model</option>
                                    {model.map((model) => (
                                      <option
                                        key={model.model_id}
                                        value={model.id}
                                      >
                                        {model.model}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.model_id}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Fuel<span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="fuel"
                                    id="fuel"
                                    className="register_input"
                                    value={formValues.fuel}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Fuel</option>
                                    {fuel.map((fuel) => (
                                      <option key={fuel.id} value={fuel.fuel}>
                                        {fuel.fuel}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.fuel}
                                  </div>
                                </div>
                              </div>
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Body Type<span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="body_type"
                                    id="body_type"
                                    className="register_input"
                                    value={formValues.body_type}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select BodyType</option>
                                    {bodytype.map((bodytype) => (
                                      <option
                                        key={bodytype.id}
                                        value={bodytype.body_type}
                                      >
                                        {bodytype.body_type}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.body_type}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Transmission
                                </label>
                                <div>
                                  <select
                                    name="transmission"
                                    id="transmission"
                                    className="register_input"
                                    value={formValues.transmission}
                                    onChange={handleChange}
                                  >
                                    <option value="">
                                      Select Transmission
                                    </option>
                                    <option value="M">Manual</option>
                                    <option value="A">Automatic</option>
                                    <option value="C">
                                      CVT (continuously variable transmission)
                                    </option>
                                    <option value="S">Semi - Automatic</option>
                                    <option value="T">Tronic</option>
                                    <option value="Other">Other</option>
                                  </select>
                                </div>
                              </div>
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Year Of Vehicle
                                  <span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="year"
                                    id="year"
                                    className="register_input"
                                    value={formValues.year}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Year</option>
                                    {generateYearOptions()}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.year}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Colour<span className="astrick">*</span>
                                </label>
                                <div>
                                  <select
                                    name="colour"
                                    id="colour"
                                    className="register_input"
                                    value={formValues.colour}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Colour</option>
                                    {colour.map((colour) => (
                                      <option
                                        key={colour.id}
                                        value={colour.colour}
                                      >
                                        {colour.colour}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.colour}
                                  </div>
                                </div>
                              </div>
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Engine Size
                                  {/* <span className="astrick">*</span> */}
                                </label>
                                <div>
                                  <select
                                    name="engine_size"
                                    id="engine_size"
                                    className="register_input"
                                    value={formValues.engine_size}
                                    onChange={handleChange}
                                  >
                                    {Object.entries(engine).map(
                                      ([value, label]) => (
                                        <option key={value} value={value}>
                                          {label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <div className="error-message">
                                    {formErrors.engine_size}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Taxed Until Date
                                </label>
                                <div>
                                  <input
                                    type="date"
                                    className="register_input"
                                    name="tax_date"
                                    value={formValues.tax_date}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="car_div">
                                <label className="register_label company_name">
                                  NCT/MOT Date
                                </label>
                                <div>
                                  <input
                                    type="date"
                                    className="register_input"
                                    name="nct_date"
                                    value={formValues.nct_date}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Mileage / Kilometers
                                </label>
                                <div>
                                  <input
                                    type="number"
                                    className="register_input"
                                    name="mileage"
                                    value={formValues.mileage}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="car_div">
                                <label className="register_label company_name">
                                  Vehicle Version
                                </label>
                                <div>
                                  <input
                                    type="number"
                                    className="register_input"
                                    name="version"
                                    value={formValues.version}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div className="car_div">
                                <label className="register_label company_name">
                                  CO2 Emissions
                                </label>
                                <div>
                                  <input
                                    type="number"
                                    className="register_input"
                                    name="co2_emissions"
                                    value={formValues.co2_emissions}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div>
                                <label className="register_label company_name">
                                  Comfort/Entertainment
                                </label>
                                <div className="checkbox_div">
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort1"
                                      name="comfort"
                                      value="Air Conditioning"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Air Conditioning"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort1">
                                      {" "}
                                      Air Conditioning
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort2"
                                      name="comfort"
                                      value="Phone Kit"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes("Phone Kit")
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort2"> Phone Kit</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort3"
                                      name="comfort"
                                      value="Front Electric Windows"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Front Electric Windows"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort3">
                                      {" "}
                                      Front Electric Windows
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort4"
                                      name="comfort"
                                      value="Rear Electric Windows"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Rear Electric Windows"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort4">
                                      {" "}
                                      Rear Electric Windows
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort5"
                                      name="comfort"
                                      value="Heated Seats"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Heated Seats"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort5"> Heated Seats</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort6"
                                      name="comfort"
                                      value="Leather Seats"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Leather Seats"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort6"> Leather Seats</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort7"
                                      name="comfort"
                                      value="Cloth interior"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Cloth interior"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort7">
                                      {" "}
                                      Cloth interior
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort8"
                                      name="comfort"
                                      value="Split Rear Seats"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Split Rear Seats"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort8">
                                      {" "}
                                      Split Rear Seats
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort9"
                                      name="comfort"
                                      value="Bluetooth"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes("Bluetooth")
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort9"> Bluetooth</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort10"
                                      name="comfort"
                                      value="DVD System"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "DVD System"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort10"> DVD System</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort11"
                                      name="comfort"
                                      value="Metallic Paint"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Metallic Paint"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort11">
                                      {" "}
                                      Metallic Paint
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort12"
                                      name="comfort"
                                      value="Colour Coded"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Colour Coded"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort12"> Colour Coded</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort13"
                                      name="comfort"
                                      value="Lumbar Support"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Lumbar Support"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort13">
                                      {" "}
                                      Lumbar Support
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort14"
                                      name="comfort"
                                      value="Roof Rail"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes("Roof Rail")
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort14"> Roof Rail</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort15"
                                      name="comfort"
                                      value="Electric Front Seats"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Electric Front Seats"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort15">
                                      {" "}
                                      Electric Front Seats
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort16"
                                      name="comfort"
                                      value="Trip Computer"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes(
                                          "Trip Computer"
                                        )
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort16">
                                      {" "}
                                      Trip Computer
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="comfort17"
                                      name="comfort"
                                      value="6 Disc CD"
                                      checked={
                                        formValues.comfort &&
                                        formValues.comfort.includes("6 Disc CD")
                                      }
                                      onChange={handleComfortCheck}
                                    />
                                    <label for="comfort17"> 6 Disc CD</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div>
                                <label className="register_label company_name">
                                  Visibility Aids
                                </label>
                                <div className="checkbox_div">
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility1"
                                      name="visibility_aids"
                                      value="Heated Mirrors"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Heated Mirrors"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility1">
                                      {" "}
                                      Heated Mirrors
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility2"
                                      name="visibility_aids"
                                      value="Xenon Headlights"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Xenon Headlights"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility2">
                                      {" "}
                                      Xenon Headlights
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility3"
                                      name="visibility_aids"
                                      value="Headlamp Wash"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Headlamp Wash"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility3">
                                      {" "}
                                      Headlamp Wash
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility4"
                                      name="visibility_aids"
                                      value="Manual Rain Sensors"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Manual Rain Sensors"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility4">
                                      {" "}
                                      Manual Rain Sensors
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility5"
                                      name="visibility_aids"
                                      value="Parking Sensors"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Parking Sensors"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility5">
                                      {" "}
                                      Parking Sensors
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility6"
                                      name="visibility_aids"
                                      value="Reversing Camera"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Reversing Camera"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility6">
                                      {" "}
                                      Reversing Camera
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="visibility7"
                                      name="visibility_aids"
                                      value="Front Fogs"
                                      checked={
                                        formValues.visibility_aids &&
                                        formValues.visibility_aids.includes(
                                          "Front Fogs"
                                        )
                                      }
                                      onChange={handleVisibiltyCheck}
                                    />
                                    <label for="visibility7"> Front Fogs</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="car_main_div">
                              <div>
                                <label className="register_label company_name">
                                  Safety/Security
                                </label>
                                <div className="checkbox_div">
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security1"
                                      name="security"
                                      value="Anti Lock Brakes"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Anti Lock Brakes"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security1">
                                      {" "}
                                      Anti Lock Brakes
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security2"
                                      name="security"
                                      value="ESP"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes("ESP")
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security2"> ESP</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security3"
                                      name="security"
                                      value="Power Steering"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Power Steering"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security3">
                                      {" "}
                                      Power Steering
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security4"
                                      name="security"
                                      value="Rake/Reach Steering"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Rake/Reach Steering"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security4">
                                      {" "}
                                      Rake/Reach Steering
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security5"
                                      name="security"
                                      value="Remote Locking"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Remote Locking"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security5">
                                      {" "}
                                      Remote Locking
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security6"
                                      name="security"
                                      value="Immobiliser"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Immobiliser"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security6"> Immobiliser</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security7"
                                      name="security"
                                      value="Alarm"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes("Alarm")
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security7"> Alarm</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security8"
                                      name="security"
                                      value="Side Airbags"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Side Airbags"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security8"> Side Airbags</label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security9"
                                      name="security"
                                      value="Driver Airbag"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Driver Airbag"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security9">
                                      {" "}
                                      Driver Airbag
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security10"
                                      name="security"
                                      value="Passenger Airbag"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "Passenger Airbag"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security10">
                                      {" "}
                                      Passenger Airbag
                                    </label>
                                  </div>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id="security11"
                                      name="security"
                                      value="3x3 Rear Seat Belts"
                                      checked={
                                        formValues.security &&
                                        formValues.security.includes(
                                          "3x3 Rear Seat Belts"
                                        )
                                      }
                                      onChange={handleSecurityCheck}
                                    />
                                    <label for="security11">
                                      {" "}
                                      3x3 Rear Seat Belts
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="place_ad_form_div">
                        <div className="ad_left_portion">
                          <label className="register_label company_name">
                            Mobile <span className="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            className="register_input"
                            name="mobile"
                            value={formValues.mobile}
                            onChange={handleChange}
                            maxLength="20"
                          />
                          <div className="error-message">
                            {formErrors.mobile}
                          </div>
                        </div>
                        <div className="ad_right_portion">
                          <label className="register_label company_name">
                            Telephone
                          </label>
                          <input
                            type="text"
                            className="register_input"
                            name="telephone"
                            value={formValues.telephone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      {tokenObject.user_type == "private" &&
                        shouldShowHeading && (
                          <h4 className="mt-4">Place in Printed Edition</h4>
                        )}
                      {tokenObject.user_type == "private" && (
                        <>
                          <div
                            className="optional edition1"
                            style={{
                              display: edition1Visible ? "block" : "none",
                            }}
                          >
                            <label htmlFor="edition_one">
                              Donegal, Northwest &amp; West
                            </label>
                            <select
                              name="edition_one"
                              className="form-control"
                              value={formValues.edition_one}
                              onChange={handleChange}
                            >
                              <option value="yes">Yes, Print My Advert</option>
                              <option value="no">
                                No, Do Not Print My Advert
                              </option>
                            </select>
                          </div>
                          <div
                            className="optional edition2"
                            style={{
                              display: edition2Visible ? "block" : "none",
                            }}
                          >
                            {/* <h4>Place in Printed Edition</h4> */}
                            <label htmlFor="edition_two">
                              Northern Ireland
                            </label>
                            <select
                              name="edition_two"
                              className="form-control"
                              value={formValues.edition_two}
                              onChange={handleChange}
                            >
                              <option value="yes">Yes, Print My Advert</option>
                              <option value="no">
                                No, Do Not Print My Advert
                              </option>
                            </select>
                          </div>
                          <div
                            className="optional edition3"
                            style={{
                              display: edition3Visible ? "block" : "none",
                            }}
                          >
                            {/* <h4>Place in Printed Edition</h4> */}
                            <label htmlFor="edition_three">
                              Ireland Midlands
                            </label>
                            <select
                              name="edition_three"
                              className="form-control"
                              value={formValues.edition_three}
                              onChange={handleChange}
                            >
                              <option value="yes">Yes, Print My Advert</option>
                              <option value="no">
                                No, Do Not Print My Advert
                              </option>
                            </select>
                          </div>
                        </>
                      )}

                      <div className="mt-3">
                        <input
                          type="checkbox"
                          id="publish1"
                          value={formValues.email_publish}
                          name="email_publish"
                          checked={formValues.email_publish}
                          onChange={handleChange}
                        />{" "}
                        <label for="publish1">
                          {" "}
                          Email published in print or online
                        </label>{" "}
                        <br />
                        <input
                          type="checkbox"
                          id="publish2"
                          value={formValues.mobile_publish}
                          name="mobile_publish"
                          checked={formValues.mobile_publish}
                          onChange={handleChange}
                        />{" "}
                        <label for="publish2">
                          {" "}
                          Mobile published in print or online
                        </label>
                        <br />
                        <input
                          type="checkbox"
                          id="publish3"
                          value={formValues.telephone_publish}
                          name="telephone_publish"
                          checked={formValues.telephone_publish}
                          onChange={handleChange}
                        />{" "}
                        <label for="publish3">
                          {" "}
                          Telephone published in print or online
                        </label>
                        {formErrors.publish && (
                          <div className="error-message">
                            {formErrors.publish}
                          </div>
                        )}
                      </div>

                      <div className="submit_place_ad">
                        <button type="submit" className="create_account_btn">
                          Next Step
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="advert_suggestion">
              <div className="advert_div">
                <h1 className="place_ad_heading_info">
                  <div className="info_place_add">
                    <IoCreate className="place_icon" />
                    <p>Place An Add</p>
                  </div>
                </h1>
                <p>
                  Posting an ad on TheDealer is free! However, all ads must
                  follow our rules:
                </p>
                <p>
                  <span>
                    <IoIosArrowForward />
                  </span>{" "}
                  Make sure you post in the correct category.
                </p>
                <p>
                  <span>
                    <IoIosArrowForward />
                  </span>{" "}
                  Use a brief title and description of the item.
                </p>
                <p>
                  <span>
                    <IoIosArrowForward />
                  </span>{" "}
                  Put a reasonable price.
                </p>
                <p>
                  <span>
                    <IoIosArrowForward />
                  </span>{" "}
                  Check the item before publish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaceAdd;
