import React, { useState, useEffect } from "react";
import "./SingleProduct.css";
import ProductPage from "./ProductPage";
import { FaCheck, FaEye, FaRegHeart, FaHeart } from "react-icons/fa";
import facebook from "../Images/business_fb.png";
import twitter from "../Images/twittericon.png";
import pintrest from "../Images/business_pintrest.png";
import mail from "../Images/mail.png";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { IoCartOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineEmail, MdPhone } from "react-icons/md";
import banner from "../Images/myvehicle_sqr.gif";
import { IoMdTime } from "react-icons/io";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { BsGlobe2 } from "react-icons/bs";
import { IoFolderOpenOutline } from "react-icons/io5";
import MapEmbed from "./MapEmbed";
import { Tab, Tabs, Toast, Breadcrumb, Nav } from "react-bootstrap";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router";
import { DealerApi } from "./Api";
import axios from "axios";
import { Helmet } from "react-helmet";
import ProductCard from "./ProductCard";
import ProductNotFound from "./ProductNotFound";
import { decode } from "html-entities";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ConfirmationDialog from "./ConfirmationDialog";

const SingleProduct = () => {
  const url = useParams();
  const id = url.id;
  const [message, setMessage] = useState("");
  const [offerAmount, setOfferAmount] = useState("");
  const [privateMessage, setPrivateMessage] = useState("");
  const [getMessage, setGetMessage] = useState([]);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [offerSuccess, setOfferSuccess] = useState(false);
  const [offerError, setOfferError] = useState(false);

  const [productData, setProductData] = useState("");
  const [images, setImages] = useState([]);
  const [feature, setFeature] = useState([]);

  const [relatedProduct, setRelatedProduct] = useState([]);

  const showDetailsTab =
    productData.sub_category_id === 47 || productData.sub_category_id === 52;
  const showbanner = productData.sub_category_id === 47 || productData.sub_category_id === 59 || productData.sub_category_id === 63;

  const [isWishlist, setIsWishlist] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const navigate = useNavigate();
  const token = Cookies.get("loginToken");
  const tokenObject = token && JSON.parse(token);
  const [isLogin, setIsLogin] = useState(
    tokenObject === undefined ? "false" : "true"
  );
  const [errMsg, setErrMsg] = useState(false);
  const [amountErr, setAmountErr] = useState(false);
  const [msgErr, setMsgErr] = useState(false);
  const [productStatus, setProductStatus] = useState("");
  const listing = Cookies.get("listingToken");
  const listingObject = listing && JSON.parse(listing);
  const [apiKey, setApiKey] = useState(null);
  const [load, setLoad] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bumpSuccess, setBumpSuccess] = useState(false);
  const [bumpError, setBumpError] = useState(false);


  document.title =
    productData && productData !== ""
      ? `${productData.title}, ${productData.item_status}, ${productData.location}`
      : "THE DEALER :: thedealer.ie :: buy, sell, exchange online in Ireland, Northern Ireland";

  useEffect(() => {
    setLoad(true);
    axios
      .get(`${DealerApi}/listing/${id}?details_page=1`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoad(false);
        const isEmptyObject = (obj) => {
          return Object.keys(obj).length === 0 && obj.constructor === Object;
        };
        const isBlank = isEmptyObject(res.data.body.result);
        setProductStatus(isBlank);
        if (isBlank === false) {
          console.log("listing/id",res.data.body.result)
          setProductData(res.data.body.result);
          setImages(res.data.body.result.images);
          setFeature(res.data.body.result.search_options);
          const category = res.data.body.result.sub_category_id;
          const list = res.data.body.result.id;
          if (category) {
            axios
              .get(`${DealerApi}/listing/releted/${category}/${list}`, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                setRelatedProduct(res.data.body.result);
              })
              .catch((error) => {});
          }

          const wishlist = (list) => {
            axios
              .get(`${DealerApi}/wishlist/${tokenObject.id}`)
              .then((res) => {
                const isInWishlist = (itemId) => {
                  return res?.data?.body?.result?.data?.some(
                    (item) => item.id === itemId
                  );
                };
                setIsWishlist(isInWishlist(list));
              })
              .catch((err) => {});
          };

          if (tokenObject) {
            wishlist(list);
          }
        }
      })
      .catch((error) => {});
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/register");
  };

  const handlebanner = () => {
    window.open("https://www.myvehicle.ie/");
  };

  const handleAddToWishlist = () => {
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);

    if (tokenObject) {
      axios
        .post(`${DealerApi}/wishlist`, {
          user_id: tokenObject.id,
          listing_id: productData.id,
        })
        .then(() => {
          setIsWishlist(true);
        })
        .catch((error) => {});
    } else {
      navigate("/login");
    }
  };

  const handleRemoveFromWishlist = () => {
    const token = Cookies.get("loginToken");
    const tokenObject = token && JSON.parse(token);
    axios
      .post(`${DealerApi}/wishlist/delete`, {
        user_id: tokenObject.id,
        listing_id: productData.id,
      })
      .then(() => {
        setIsWishlist(false);
      })
      .catch((error) => {});
  };

  const handleBump = (id) => {
    if (tokenObject) {
      axios
        .patch(`${DealerApi}/listing/bump/${id}`, null, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((response) => {
          setBumpSuccess(true);
          setTimeout(() => {
            setBumpSuccess(false);
          }, 2000);
        })
        .catch((error) => {
          setBumpError(true);
          setTimeout(() => {
            setBumpError(false);
          }, 5000);
        });
    }
  };

  const handleEdit = (id) => {
    let data = { listing_id: id };
    Cookies.set("listingToken", JSON.stringify(data));
    if(tokenObject.user_type == "admin" && productData.ad_type == "business")
    {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/update/business/${id}`,
        "_self"
      );
    } else if(tokenObject.user_type == "admin" && productData.ad_type == "private") {
      window.open(
        `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/update/private/${id}`,
        "_self"
      );
    }
    else {
    navigate("/online_ad");
    }
  };

  const handleDelete = (id) => (event) => {
    event.stopPropagation();
    setItemToDelete(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    const id = itemToDelete;
    setShowModal(false);


    if (id == (listingObject && listingObject.listing_id)) {
      Cookies.remove("listingToken");
    }

    if (tokenObject) {
      axios
        .delete(`${DealerApi}/listing/${id}`, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
          },
        })
        .then((response) => {
          setDeleteSuccess(true);
          setTimeout(() => {
            setDeleteSuccess(false);
            if(tokenObject.user_type == "admin" && productData.ad_type == "business")
            {
              window.open(
                `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/business`,
                "_self"
              );
            } else if (tokenObject.user_type == "admin" && productData.ad_type == "private") {
              window.open(
                `${process.env.REACT_APP_ADMIN_APP_API_URL}/advert/index/private`,
                "_self"
              );
            } else {
              navigate("/my_online_ads");
            }
          }, 2000);
        })
        .catch((error) => {
          setDeleteError(true);
          setTimeout(() => {
            setDeleteError(false);
          }, 5000);
        });
    }

    // if (id == (listingObject && listingObject.listing_id)) {
    //   Cookies.remove("listingToken");
    // }

    // if (tokenObject) {
    //   axios
    //     .delete(`${DealerApi}/listing/${id}`, {
    //       headers: {
    //         Authorization: `Bearer ${tokenObject.token}`,
    //       },
    //     })
    //     .then((response) => {
    //       setLoad(false);
    //       setDeleteSuccess(true);
    //       if (data && data.length > 1) {
    //         getData();
    //       } else {
    //         window.location.reload();
    //       }
    //       setTimeout(() => {
    //         setDeleteSuccess(false);
    //       }, 5000);
    //     })
    //     .catch((error) => {
    //       setDeleteError(true);
    //       setTimeout(() => {
    //         setDeleteError(false);
    //       }, 5000);
    //     });
    // }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setItemToDelete(null);
  };

  const getMessageData = async () => {
    await axios
      .get(`${DealerApi}/comment/get/${productData && productData.id}`)
      .then((res) => {
        setGetMessage(res.data.body.result);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (productData && productData.id) {
      getMessageData();
    }
  }, [productData]);

  const handleMessageSend = () => {
    let message_data = {
      to_id: productData && productData.user_id,
      from_id: tokenObject && tokenObject.id,
      description: message,
      listing_id: productData && productData.id,
    };

    if (message != "") {
      setErrMsg(false);
      axios
        .post(`${DealerApi}/comment/question`, message_data, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setMessageSuccess(true);
          setTimeout(() => {
            setMessageSuccess(false);
          }, 5000);
          getMessageData();
          setMessage("");
        })
        .catch((error) => {
          setMessageError(true);
          setTimeout(() => {
            setMessageError(false);
          }, 5000);
        });
    } else {
      setErrMsg(true);
    }
  };

  const handleOfferSend = () => {
    let message_data = {
      to_id: productData && productData.user_id,
      from_id: tokenObject && tokenObject.id,
      description: privateMessage,
      listing_id: productData && productData.id,
      offer_amount: offerAmount,
    };

    if (offerAmount != "" && privateMessage != "") {
      axios
        .post(`${DealerApi}/comment/offer`, message_data, {
          headers: {
            Authorization: `Bearer ${tokenObject.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setOfferSuccess(true);
          setTimeout(() => {
            setOfferSuccess(false);
          }, 5000);
          getMessageData();
          setOfferAmount("");
          setPrivateMessage("");
        })
        .catch((error) => {
          setOfferError(true);
          setTimeout(() => {
            setOfferError(false);
          }, 5000);
        });
    } else {
      if (offerAmount == "" && privateMessage == "") {
        setAmountErr(true);
        setMsgErr(true);
      } else if (offerAmount == "") {
        setAmountErr(true);
      } else {
        setMsgErr(true);
      }
    }
  };

  const wishListFunction = async () => {
    if (tokenObject) {
      await axios
        .get(`${DealerApi}/wishlist/${tokenObject.id}`)
        .then((res) => {
          setWishlist(res.data.body.result.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    wishListFunction();
  }, []);

  const updateWishlist = () => {
    wishListFunction();
  };

  const isInWishlist = (itemId) => {
    return wishlist && wishlist.some((item) => item.id === itemId);
  };

  const convertToUrl = (text) => {
    text = text.trim().toLowerCase();
    text = text.replace(/\s+/g, "-");
    text = text.replace(/(\d+)\.(\d+)/g, "$1-$2");
    text = text.replace(/[^a-z0-9-]/g, "");
    return text;
  };

  const productUrl = `${process.env.REACT_APP_API_SOCIAL}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`;

  useEffect(() => {
    // Update the title
    // document.title = 'Your Website Title';
    if (productData && productData.ad_title && productData.description && productData.images) {
    // Create a meta element for og:title
    const ogTitleMeta = document.createElement('meta');
    ogTitleMeta.setAttribute('property', 'og:title');
    ogTitleMeta.setAttribute('content', `${productData && productData.ad_title}`);
    document.head.appendChild(ogTitleMeta);

    // Create a meta element for og:description
    const ogDescriptionMeta = document.createElement('meta');
    ogDescriptionMeta.setAttribute('property', 'og:description');
    ogDescriptionMeta.setAttribute(
      'content',
      `${productData && productData.description}`
    );
    document.head.appendChild(ogDescriptionMeta);

    // Create a meta element for og:image
    const ogImageMeta = document.createElement('meta');
    ogImageMeta.setAttribute('property', 'og:image');
    ogImageMeta.setAttribute(
      'content',
      `${productData.images && productData.images.length > 0 && productData.images[0].picture.url.small}`
    );
    document.head.appendChild(ogImageMeta);

    // Create a meta element for og:url
    const ogUrlMeta = document.createElement('meta');
    ogUrlMeta.setAttribute('property', 'og:url');
    ogUrlMeta.setAttribute(
      'content',
      `${productUrl}`
    );
    document.head.appendChild(ogUrlMeta);

    // Clean up function to remove the added meta tags
    return () => {
      document.head.removeChild(ogTitleMeta);
      document.head.removeChild(ogDescriptionMeta);
      document.head.removeChild(ogImageMeta);
      document.head.removeChild(ogUrlMeta);
    };
   }
  }, [productData, productUrl]);

  const urlText = convertToUrl(productData && productData.ad_title);

  const handleFacebook = () => {
    // const urlText = convertToUrl(productData.ad_title);
    if (productData) {
      // const productUrl = `${process.env.REACT_APP_API_SOCIAL}/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`;
      // const imageUrl = encodeURIComponent(
      //   productData && productData.images[0].picture.url.small
      // );
      // const description = encodeURIComponent(
      //   productData && productData.description
      // );
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${productUrl}`;

      window.open(facebookShareUrl, "_blank", "width=600,height=400");
    }
  };

  const handlePintrest = () => {
    const description = encodeURIComponent(
      productData && productData.description
    );
    const imageUrl = encodeURIComponent(
      productData && productData.images[0].picture.url.small
    );
    const url = encodeURIComponent(
      `${process.env.REACT_APP_API_SOCIAL}/${id}/${urlText}`
    );

    const pinterestShareUrl = `https://in.pinterest.com/pin-builder/?description=${description}&media=${imageUrl}&url=${url}`;

    window.open(pinterestShareUrl, "_blank", "width=600,height=400");
  };

  const handleMail = () => {
    if (productData) {
      const subject = encodeURIComponent(
        `${productData.ad_title}, ${productData.item_status}, ${productData.location}`
      );
      const body = encodeURIComponent(`${productData.description}`);
      const url = `mailto:?subject=${subject}&body=${body}`;
      window.open(url, "_blank", "width=600,height=400");
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      window.print();
    }
  };

  const handlePrint = (event) => {
    event.preventDefault();
    window.print();
  };

  const handleReportMouseDown = (event) => {
    event.preventDefault();
    if (event.button === 1) {
      window.open(`/report_an_advert/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`);
    }
  };

  const handleReport = (event) => {
    event.preventDefault();
    navigate(`/report_an_advert/${url.category}/${url.subcategory}/${url.county}/${url.adtype}/${url.id}/${url.slug}`);
  };

  const fetchApiKey = async () => {
    try {
      const response = await axios.get(`${DealerApi}/keys`);
      setApiKey(response.data.body.result.google_api_key.google_api);
    } catch (error) {}
  };

  fetchApiKey();

  const handleGoBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // window.history.back();
    // navigate(-1);
    window.location.href = document.referrer;
  };

  const renderDescription = (description) => {
    const decodedDescription = decode(description);

    return decodedDescription.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleUserName = (user) => {
    if (user.user_type == "business") {
      navigate(
        `/business_directory/details/${user.id}/${convertToUrl(
          user.company_name
        )}`
      );
    }
  };

  const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  function truncateStringWithEllipsis(text, wordLimit = 20) {
    // Split the text into words
    const words = text.split(' ');
  
    // Check if the word count exceeds the limit
    if (words.length > wordLimit) {
      // Slice the words array to the word limit and join them back into a string
      return words.slice(0, wordLimit).join(' ') + '...';
    }
  
    // If the word count is within the limit, return the original text
    return text;
  }

  return (
    <>
    <Helmet>
      <title>{productData.meta_title}</title>
      <meta name="description" content={productData.meta_description} />
      <meta name="keywords" content={productData.meta_keyword} />
        <meta name="twitter:card" content={productData && productData.images[0].picture.url.small} />
        <meta name="twitter:title" content={productData.ad_title} />
        <meta name="twitter:description" content={productData.description} />
        <meta name="twitter:image" content={productData && productData.images[0].picture.url.small} />

        <meta name="pinterest:description" content={productData.description} />
        <meta name="pinterest:image" content={productData && productData.images[0].picture.url.small} />
      </Helmet>
      <div className="container">
        <div className="product_load_img_div">
          {load && (
            <FontAwesomeIcon icon={faSpinner} spin className="product_loader_img" />
          )}
        </div>
      </div>
      {!load && productStatus === "" ? (
        <p className="inactive_div" />
      ) : !load && productStatus === true ? (
        <ProductNotFound />
      ) : (
        !load && productStatus === false && (
          <div className="section-padding">
            <div className="container">
            <Toast
              className="fade bg-success toast_message_div"
              show={deleteSuccess}
              onClose={() => setDeleteSuccess(false)}
            >
              <Toast.Body className="toast_message">
                Deleted successfully!
              </Toast.Body>
            </Toast>
            <Toast
              show={deleteError}
              onClose={() => setDeleteError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">
                Error deleting the listing.
              </Toast.Body>
            </Toast>
            <Toast
              className="fade bg-success toast_message_div"
              show={bumpSuccess}
              onClose={() => setBumpSuccess(false)}
            >
              <Toast.Body className="toast_message">
                Listing bumped successfully!
              </Toast.Body>
            </Toast>

            <Toast
              show={bumpError}
              onClose={() => setBumpError(false)}
              className="fade bg-danger toast_message_div"
            >
              <Toast.Body className="toast_message">
                Error bumping the listing.
              </Toast.Body>
            </Toast>
              {productData && (
                <Breadcrumb>
                  <Breadcrumb.Item
                    className="back_breadcrumb"
                    onClick={handleGoBack}
                  >
                    Back
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href="/" className="home_breadcrumb">
                    Home
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/${productData.category_slug}`}
                    className="home_breadcrumb"
                  >
                    {productData.category_slug}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/${productData.category_slug}/${productData.sub_category_slug}`}
                    className="home_breadcrumb"
                  >
                    {productData.sub_category_slug}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className="home_breadcrumb active">
                    {productData.title}
                  </Breadcrumb.Item>
                </Breadcrumb>
              )}
              <div className="row">
                <div className="col-lg-9 box">
                  <div className="product-info row">
                    <div className="col-lg-7 col-md-12 col-xs-12">
                      {images && images.length > 0 && (
                        <div>
                          <ProductPage
                            images={images}
                            price={productData.price}
                            currency_sign={productData.currency_sign}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-lg-5 col-md-12 col-xs-12">
                      <div className="details-box">
                        <div className="ads-details-info">
                          <h2>{productData.title}</h2>
                          <div className="details-meta">
                            <span>
                              <IoMdTime />
                              {productData.days === 0 || productData.days === 1
                                ? `${productData.days} day ago`
                                : `${productData.days} days ago`}
                            </span>
                            <span>
                              <IoFolderOpenOutline /> {productData.category}
                            </span>
                            <span>
                              <FaLocationDot /> {productData.location}
                            </span>
                            <span>
                              <FaEye /> {productData.views} View
                            </span>
                          </div>
                          <div className="description">
                            <h4 className="title-small">Description</h4>
                            {productData.description && (
                              <div>
                                {renderDescription(productData.description)}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="share">
                          <span className="print_btn">Share: </span>
                          <div className="social_media_share_report_div">
                            <div className="social-link">
                              <img
                                src={facebook}
                                alt="fb"
                                className="facebook"
                                onClick={handleFacebook}
                              />
                              <a
                                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                                  productUrl
                                )}&text=${encodeURIComponent(
                                  truncateStringWithEllipsis(productData.description)
                                )}`}
                              >
                                <img
                                  src={twitter}
                                  alt="twitter"
                                  className="twitter"
                                />
                              </a>
                              <img
                                src={pintrest}
                                alt="linkedin"
                                className="linkedin"
                                onClick={handlePintrest}
                              />
                              <img
                                src={mail}
                                alt="mail"
                                className="google"
                                onClick={handleMail}
                              />
                            </div>
                            <div className="print_btn_div">
                              <Nav.Link as={Link} onClick={handlePrint} onMouseDown={handleMouseDown}>
                              <span
                                className="print_btn login_btn"
                              >
                                Print
                              </span>
                              </Nav.Link>
                              <Nav.Link as={Link} onClick={handleReport} onMouseDown={handleReportMouseDown}>
                              <span
                                className="print_btn login_btn"
                              >
                                Report
                              </span>
                              </Nav.Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {tokenObject && (tokenObject.id === productData.user_id || tokenObject.user_type == "admin")&& (
                    <div className="button_div">
                      <span onClick={() => handleEdit(productData.id)}>
                        Edit
                      </span>
                      <span onClick={() => handleBump(productData.id)}>
                        Bump
                      </span>
                      <span onClick={(event) => handleDelete(productData.id)(event)}>
                        Remove
                      </span>
                    </div>
                  )}
                  <Tabs id="additional-details-tabs" className="tabs">
                    {showDetailsTab && (
                      <Tab eventKey="details" title="Details">
                        <div className="card-head">
                          <h5 className="mb-4">Additional Detail</h5>
                          <div className="detail_main_div">
                            {productData.make && (
                              <div className="detail_div">
                                <span className="font-weight-bold">Make :</span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {productData.make}
                                </span>
                              </div>
                            )}
                            {productData.model && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Model :
                                </span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {productData.model}
                                </span>
                              </div>
                            )}
                            {productData.fuel && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Fuel Type :
                                </span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {capitalizeWord(productData.fuel)}
                                </span>
                              </div>
                            )}
                            {productData.body_type && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Body Type :
                                </span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {productData.body_type}
                                </span>
                              </div>
                            )}
                            {productData.transmission && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Transmission :
                                </span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {productData.transmission}
                                </span>
                              </div>
                            )}
                            {productData.year && (
                              <div className="detail_div">
                                <span className="font-weight-bold">Year :</span>{" "}
                                <span className="value_specification">
                                  {" "}
                                  {productData.year}
                                </span>
                              </div>
                            )}
                            {productData.colour && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Color :
                                </span>
                                <span className="value_specification">
                                  {" "}
                                  {capitalizeWord(productData.colour)}
                                </span>
                              </div>
                            )}
                            {productData.engine_size && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Engine :
                                </span>
                                <span className="value_specification">
                                  {" "}
                                  {productData.engine_size}
                                </span>{" "}
                              </div>
                            )}
                            {productData.co2_emissions && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Co2 Emissions :
                                </span>
                                <span className="value_specification">
                                  {" "}
                                  {productData.co2_emissions}
                                </span>{" "}
                              </div>
                            )}
                            {productData.mileage && (
                              <div className="detail_div">
                                <span className="font-weight-bold">
                                  Mileage :
                                </span>
                                <span className="value_specification">
                                  {" "}
                                  {productData.mileage}
                                </span>{" "}
                              </div>
                            )}
                            {productData.nct_date && (
                              <div className="detail_div">
                                <span className="font-weight-bold">NCT :</span>
                                <span className="value_specification">
                                  {" "}
                                  {productData.nct_date}
                                </span>{" "}
                              </div>
                            )}
                            {productData.tax_date && (
                              <div className="detail_div">
                                <span className="font-weight-bold">Tax :</span>
                                <span className="value_specification">
                                  {" "}
                                  {productData.tax_date}
                                </span>{" "}
                              </div>
                            )}
                          </div>
                          {feature && feature.length > 0 && (
                            <>
                              <h6 className="mt-4">Features</h6>
                              <div className="detail_main_div">
                                {feature &&
                                  feature.map((item, index) => {
                                    return (
                                      <div className="detail_div" key={index}>
                                        <span className="font-weight-bold">
                                          <FaCheck />
                                        </span>{" "}
                                        <span className="value_specification">
                                          {" "}
                                          {item}
                                        </span>
                                      </div>
                                    );
                                  })}
                              </div>
                            </>
                          )}
                        </div>
                      </Tab>
                    )}

                    {productData.description && (
                      <Tab eventKey="description" title="Description">
                        <div className="card-head">
                          <div>
                            {productData.description && (
                              <div>
                                {renderDescription(productData.description)}
                              </div>
                            )}
                          </div>
                        </div>
                      </Tab>
                    )}

                    {isLogin === "true" && (
                      <Tab eventKey="question1" title="Ask a Question">
                        <div className="question_label_div">
                          <Toast
                            className="fade bg-success toast_message_div"
                            show={messageSuccess}
                            onClose={() => setMessageSuccess(false)}
                          >
                            <Toast.Body className="toast_message">
                              Your Question has been posted.
                            </Toast.Body>
                          </Toast>
                          <Toast
                            show={messageError}
                            onClose={() => setMessageError(false)}
                            className="fade bg-danger toast_message_div"
                          >
                            <Toast.Body className="toast_message">
                              Error in posting question.
                            </Toast.Body>
                          </Toast>
                          <label className="ques_label">
                            Message <span className="astrick">*</span>
                          </label>
                          <textarea
                            rows={5}
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                              setErrMsg(false);
                            }}
                          ></textarea>
                          {errMsg && (
                            <p className="error-message">
                              Enter your question above to continue.
                            </p>
                          )}
                        </div>
                        <button
                          className="send_btn"
                          onClick={handleMessageSend}
                        >
                          Send
                        </button>
                      </Tab>
                    )}

                    {isLogin === "true" && (
                      <Tab eventKey="question2" title="Make an Offer">
                        <div className="question_label_div">
                          <Toast
                            className="fade bg-success toast_message_div"
                            show={offerSuccess}
                            onClose={() => setOfferSuccess(false)}
                          >
                            <Toast.Body className="toast_message">
                              Your Offer has been posted.
                            </Toast.Body>
                          </Toast>
                          <Toast
                            show={offerError}
                            onClose={() => setOfferError(false)}
                            className="fade bg-danger toast_message_div"
                          >
                            <Toast.Body className="toast_message">
                              Error in posting offer.
                            </Toast.Body>
                          </Toast>
                          <label className="ques_label">
                            Amount <span className="astrick">*</span>
                          </label>
                          <input
                            type="number"
                            value={offerAmount}
                            onChange={(e) => {
                              setOfferAmount(e.target.value);
                              setAmountErr(false);
                            }}
                          />
                          {amountErr && (
                            <p className="error-message">
                              The offer amount is required.
                            </p>
                          )}
                        </div>
                        <div className="question_label_div">
                          <label className="ques_label">
                            Private Message <span className="astrick">*</span>
                          </label>
                          <textarea
                            rows={5}
                            value={privateMessage}
                            onChange={(e) => {
                              setPrivateMessage(e.target.value);
                              setMsgErr(false);
                            }}
                          ></textarea>
                          {msgErr && (
                            <p className="error-message">
                              The description is required.
                            </p>
                          )}
                        </div>
                        <button className="send_btn" onClick={handleOfferSend}>
                          Send
                        </button>
                      </Tab>
                    )}

                    {isLogin === "true" && (
                      <Tab eventKey="question3" title="Q & A">
                        <div className="q_and_a_div_scroll">
                          {getMessage && getMessage.length > 0 ? (
                            getMessage.map((item) => {
                              return (
                                <div className="q_ad_a_div">
                                  <BiMessageRoundedDetail className="svg_message" />
                                  <div>
                                    <p>
                                      <span className="user_name_font">
                                        {item.user_name}
                                      </span>{" "}
                                      <span> {item.date}</span>
                                    </p>
                                    <p>
                                      <span>{item.description}</span>
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p className="q_and_a_no_data">
                              There have been 0 question asked.
                            </p>
                          )}
                        </div>
                      </Tab>
                    )}

                    {isLogin === "false" && (
                      <Tab eventKey="question" title="Question/Offer">
                        <div className="login_register_div">
                          <p>
                            You must be logged in to post a question or make an
                            offer.
                          </p>

                          <div className="login_signup_div">
                          <Nav.Link as={Link} to="/login">
                            <button className="login_btn">
                              LOGIN
                            </button>
                            </Nav.Link>
                            <Nav.Link as={Link} to="/register">
                            <button
                              className="signup_btn"
                            >
                              SIGN UP
                            </button>
                            </Nav.Link>
                          </div>
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>

                <div className="col-lg-3 box_mar">
                  <div className="card card-user-info sidebar-card location_mar">
                    {/* <div className="block-cell user">
                      <div className="cell-media">
                        <img src={user} alt={productData.user_name} />
                      </div>
                      <div className="cell-content">
                        <h5 className="title_post">Posted by</h5>
                        <span
                          className={
                            productData.user.user_type == "business"
                              ? "business_user_name"
                              : "name"
                          }
                          onClick={() => handleUserName(productData.user)}
                        >
                          {productData.user_name}
                        </span>
                      </div>
                    </div> */}

                    <div className="card-content">
                      {/* <div className="card-body text-start">
                        <div className="grid-column no-page-break">
                          <div className="col_from">
                            <IoLocationOutline />
                            <span>Location</span>
                          </div>
                          <div className="col_to">
                            <span>
                              <p>{productData.user_location}</p>
                            </span>
                          </div>
                        </div>
                        <div className="grid-column no-page-break">
                          <div className="col_from">
                            <FaRegUser />
                            <span>Joined</span>
                          </div>
                          <div className="col_to">
                            <span>
                              <span title="">
                                {productData.joining_weeks} weeks ago
                              </span>
                            </span>
                          </div>
                        </div>
                      </div> */}

                      <div className="ev-action">
                        <h4 className="text-center">Contact Information</h4>
                        {(productData.telephone_publish === 1 && productData.telephone != "") && (
                            <a
                              href={`tel:${productData.telephone}`}
                              data-bs-toggle="modal"
                              className="btn emailBlock btn-block"
                            >
                              <TbDeviceLandlinePhone /> {productData.telephone}
                            </a>
                          )}
                        {(productData.mobile_publish === 1 && productData.mobile != "") && (
                            <a
                              href={`tel:${productData.mobile}`}
                              data-bs-toggle="modal"
                              className="btn emailBlock btn-block"
                            >
                              <MdPhone /> {productData.mobile}
                            </a>
                          )}
                        {(productData.email_publish === 1 && productData.email != "") && (
                            <a
                              href={`mailto:${productData.email}`}
                              data-bs-toggle="modal"
                              className="btn emailBlock btn-block"
                            >
                              <MdOutlineEmail /> Send a message
                            </a>
                          )}
                        {isWishlist ? (
                          <btn
                            className="btn emailBlock btn-block"
                            onClick={handleRemoveFromWishlist}
                          >
                            <FaHeart /> Watch
                          </btn>
                        ) : (
                          <btn
                            className="btn emailBlock btn-block"
                            onClick={handleAddToWishlist}
                          >
                            <FaRegHeart /> Watch
                          </btn>
                        )}

                        {productData.website &&
                          productData.website != "" &&
                          productData.website != null &&
                          productData.website != 0 && (
                            <a
                              href={`${productData.website}`}
                              data-bs-toggle="modal"
                              className="btn emailBlock btn-block"
                              target="_blank"
                            >
                              <BsGlobe2 /> Web
                            </a>
                          )}
                        {productData.buy_now_url &&
                          productData.buy_now_url != "" &&
                          productData.buy_now_url != null && (
                            <a
                              href={`${productData.buy_now_url}`}
                              data-bs-toggle="modal"
                              className="btn emailBlock btn-block"
                              target="_blank"
                            >
                              <IoCartOutline className="buy_now" /> Buy Now
                            </a>
                          )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="location_map_div">
                    <div className="location_map">Location's Map</div>
                    <div>
                      <MapEmbed
                        apiKey={apiKey}
                        countryName={productData.user_location}
                        latitude={productData.user_lat}
                        longitude={productData.user_long}
                      />
                    </div>
                  </div> */}

                  {showbanner && (
                    <div className="banner_div">
                      <img
                        src={banner}
                        onClick={handlebanner}
                        alt="banner_image"
                        className="banner_img_car"
                      />
                    </div>
                  )}
                </div>

                {relatedProduct.length > 0 && (
                  <>
                    <h2 className="featured_heading">RELATED LISTINGS</h2>
                    <div className="featured_line"></div>
                    <div className="related_product_grid">
                      {relatedProduct.map((product) => {
                        return (
                          <>
                            <ProductCard
                              key={product.id}
                              product={product}
                              wishlist={wishlist}
                              isInWishlist={isInWishlist(product.id)}
                              updateWishlist={updateWishlist}
                            />
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>

            <ConfirmationDialog
            show={showModal}
            handleClose={cancelDelete}
            handleConfirm={confirmDelete}
          />
          </div>
        )
      )}

    </>
  );
};

export default SingleProduct;
