import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";
import Heder from "../Component/Heder";
import { DealerApi } from "../Component/Api";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Faq = ({setting}) => {

const [data,setData]= useState([]);
const [load, setLoad] = useState(true);


useEffect(()=>{
  
  
  axios.get(`${DealerApi}/faqs`)
  .then((response) => {
    console.log("Response",response.data.body.result);
    setData(response.data.body.result)
    setLoad(false)
   
  })
  .catch((err) => {setLoad(false)});

},[])


  return (
    <>
    <Heder name="FAQ" setting={setting}/>
      <div className="faq_main_div">
        <p className="faq_heading">Frequently Asked Questions</p>
      </div>

      <div className="container">
        <div className="faq_div">
        <div className="load_img_div">
          {load && <FontAwesomeIcon icon={faSpinner} spin className="loader_img" />}
        </div>
        {(data && data.length>0) && data.map((item, index) => (
        <Accordion className={index !== 0 ? "accordion_main" : ""}  defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey={`${index}`} key={index}>
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body>{item.answer}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
        </div>
      </div>
    </>
  );
};

export default Faq;